import React from 'react';

function Footer() {
    return (
        <footer className="text-center text-white" style={{ backgroundColor: '#f1f1f1' }}>
            <div className="container pt-2">
                <section className="mb-2">
                    <a className="btn btn-link btn-floating btn-lg text-dark m-1"
                        href="https://www.facebook.com/foncre?mibextid=LQQJ4d"
                        target="_blank"
                        role="button"
                        data-mdb-ripple-color="dark">
                        <i class="fab fa-facebook-f" style={{ color: '#09345B' }}></i>
                    </a>
                    <a className="btn btn-link btn-floating btn-lg text-dark m-1"
                        href="https://www.instagram.com/foncrecapitalgroup/"
                        target="_blank"
                        role="button"
                        data-mdb-ripple-color="dark">
                        <i class="fab fa-instagram" style={{ color: '#09345B' }}></i>
                    </a>
                </section>
            </div>
            <div className="text-center text-dark p-3" style={{ backgroundColor: '#09345B' }}>
                <p style={{ color: '#FFFFFF', marginTop: '10px' }}>2021 Foncre</p>
            </div>
        </footer>
    );
}

export default Footer;
