import React from "react";
import Main from "./components/Main";
import Form from "./components/Form";
import Footer from "./components/Footer";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {

  return (
    <div className="App">
      <Main />
      <Form />
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={2500}
        closeOnClick
      />
    </div>
  );
}

export default App;
