import React from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import axios from 'axios';

function Form() {
    const [name, setName] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [email, setEmail] = React.useState(null);
    const [city, setCity] = React.useState(null);
    const [amount, setAmount] = React.useState('');
    const [send, setSend] = React.useState(false);
    const [showPixel, setShowPixel] = React.useState(false);
    const API_URL = 'https://fobe-system.xyz/froma-api/public';
    // const API_URL = 'http://localhost:8000/froma/create';

    React.useEffect(() => {
        if (phone.length > 9 && !send) {
            axios.post(`${API_URL}/froma/create`, { name, phone })
            .then((res) => {
                console.log('res leads x -> ', res);
                setSend(true);
            })
            .catch((err) => {
                console.log('err leads -> ', err);
            });
        }
    }, [phone])

    const save = (event) => {
        event.preventDefault();
        if ( name !== '' && phone !== '' && amount !== '') {
            const data = { name, phone, email: email || '-', city: city || '-', amount };
            axios.post(`${API_URL}/froma/create`, data)
                .then((res) => {
                    console.log('res leads -> ', res);
                    setName('');
                    setPhone('');
                    setEmail('');
                    setCity('');
                    setAmount('');
                    setShowPixel(true);
                    setTimeout(() => {
                        setShowPixel(false);
                    }, 2500);
                    toast.success('Los datos se han enviado.');
                })
                .catch((err) => {
                    toast.error('Ha ocurrido un error, intentalo más tarde.');
                });
        } else {
            toast.error('Todos los campos son obligatorios');
        }
    };

    return (
        <div className="w-100 h-auto mb-2" style={{ backgroundColor: '#F1F1F1', paddingLeft: 10, paddingRight: 10 }}>
            <div className="form-title">
                <h3 className="black">
                    Déjanos tus datos para obtener más información.
                </h3>
                {showPixel
                    ? <img src="https://leadgenios.net/pixel.track?CID=451312&p=img&MerchantReferenceID=" width="1" height="1" border="0"/>
                    : null}
            </div>
            <Card sx={{ minWidth: 275, maxWidth: 800, margin: 'auto', paddingLeft: 2, paddingRight: 2, paddingTop: 2 }}>
                <CardContent>
                    <form>
                        <div className="form-group">
                            <label className="medium" for="exampleFormControlInput1">Nombre completo:</label>
                            <input type="text" className="form-control medium" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label className="medium" for="exampleFormControlInput2">Teléfono personal:</label>
                            <input type="phone" className="form-control medium" id="exampleFormControlInput2" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label className="medium" for="exampleFormControlInput4">Monto de inversión:</label>
                            <input type="text" className="form-control medium" id="exampleFormControlInput4" value={amount} onChange={(e) => setAmount(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label className="medium" for="exampleFormControlInput3">Correo electrónico:</label>
                            <input type="email" className="form-control medium" id="exampleFormControlInput3" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label className="medium" for="exampleFormControlInput4">Ciudad:</label>
                            <input type="text" className="form-control medium" id="exampleFormControlInput4" value={city} onChange={(e) => setCity(e.target.value)} />
                        </div>
                        <div className="form-button">
                            <button className="btn btn-primary black" onClick={(e) => save(e)}>Enviar</button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}

export default Form;
