import React from 'react';

import Logo from '../images/logoWhite.png';

import Calculator from './Calculator';

function Main() {
    return (
        <div className="main">
            <div className="container-main">
                {/* <div className="main-title">
                </div> */}
                <div className="main-logo">
                    <img src={Logo} alt="logo" style={{ height: '100px', width: 'auto' }} />
                </div>
            </div>
            <Calculator />
        </div>
    );
}

export default Main;
